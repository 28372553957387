<template>
  <div class="field">
    <div class="control">
      <template v-if="tag=='textarea'">
        <label class="label">{{label}}</label>
        <textarea
          class="textarea"
          :class="classes"
          @change="sauverOption"
          @keyup="sauverOption"
          v-model="valeur"
        />
      </template>
      <template v-else>
        <label class="checkbox" v-if="tag=='checkbox'">
          <input type="checkbox" @change="sauverOption" v-model="valeur" />
          {{label}}
        </label>

        <template v-else>
          <label class="label">{{label}}</label>
          <input
            :type="tag"
            class="input"
            :class="classes"
            @change="sauverOption"
            @keyup="sauverOption"
            v-model="valeur"
          />
        </template>
      </template>
    </div>
    <p class="help"><span v-if="hint" v-html="hint"></span><slot/></p>
  </div>
</template>

<script>
export default {
  props: ["tag", "label", "cle", "hint"],
  data() {
    return {
      valeur: "",
      valeurPrec: "",
      ok: "",
      si: false,
      chargement: false,
    };
  },
  mounted() {
    this.valeur = this.valeurPrec = this.$store.state.options[this.cle];
  },
  computed: {
    classes() {
      let classes = [];
      if (this.chargement) {
        classes.push("is-danger");
      } else if (this.ok) {
        classes.push("is-success");
      }
      return classes.join(" ");
    },
  },
  methods: {
    sauverOption() {
      if (!this.chargement && this.valeurPrec != this.valeur) {
        this.chargement = true;
        clearTimeout(this.si);
        this.si = setTimeout(() => {
          api
            .post(`option/${this.cle}`, { valeur: this.valeur, token : this.$store.state.token })
            .then((response) => {
              let option = response.data;
              this.$store.commit("setOption", option);
              this.valeurPrec = this.valeur;
              this.chargement = false;
              this.ok = true;
              setTimeout(() => (this.ok = false), 3000);
            });
        }, 1000);
      }
    },
  },
};
</script>
